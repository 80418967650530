import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Simulator = makeShortcode("Simulator");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Starswap v1 was launched in November 2018 as a proof of concept for automated market makers (AMMs), a type of exchange where anyone can pool assets into shared market making strategies.`}</p>
    <p>{`In May 2020, Starswap v2 introduced new features and optimizations, setting the stage for exponential growth in AMM adoption. Less than one year since its launch, v2 has facilitated over `}{`$`}{`135bn in trading volume, ranking as one of the largest cryptocurrency spot exchanges in the world.`}</p>
    <p>{`Starswap now serves as critical infrastructure for decentralized finance, empowering developers, traders, and liquidity providers to participate in a secure and robust financial marketplace.`}</p>
    <p>{`Today, we are excited to present an overview of Starswap v3. We are targeting an `}<strong parentName="p">{`L1 Ethereum mainnet launch on May 5`}</strong>{`, with an `}<strong parentName="p">{`L2 deployment on Optimism set to follow shortly after.`}</strong></p>
    <p>{`Starswap v3 introduces:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Concentrated liquidity,`}</strong>{` giving individual LPs granular control over what price ranges their capital is allocated to. Individual positions are aggregated together into a single pool, forming one combined curve for users to trade against`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Multiple fee tiers`}</strong>{` , allowing LPs to be appropriately compensated for taking on varying degrees of risk`}</p>
      </li>
    </ul>
    <p>{`These features make Starswap v3 `}<strong parentName="p">{`the most flexible and efficient AMM ever designed`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`LPs can provide liquidity with `}<strong parentName="p">{`up to 4000x capital efficiency`}</strong>{` relative to Starswap v2, earning `}<strong parentName="p">{`higher returns on their capital`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Capital efficiency paves the way for low-slippage `}<strong parentName="p">{`trade execution that can surpass both centralized exchanges and stablecoin-focused AMMs`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`LPs can significantly `}<strong parentName="p">{`increase their exposure to preferred assets`}</strong>{` and `}<strong parentName="p">{`reduce their downside risk`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`LPs can sell one asset for another by adding liquidity to a price range entirely above or below the market price, approximating `}<strong parentName="p">{`a fee-earning limit order that executes along a smooth curve`}</strong></p>
      </li>
    </ul>
    <p>{`Starswap`}{`’`}{`s `}<strong parentName="p">{`oracles are now far easier and cheaper to integrate`}</strong>{`. V3 oracles are capable of providing time-weighted average prices (TWAPs) on demand for any period within the last ~9 days. This removes the need for integrators to checkpoint historical values.`}</p>
    <p>{`Even with these groundbreaking design improvements, the `}<strong parentName="p">{`gas cost of v3 swaps on Ethereum mainnet is slightly cheaper than v2`}</strong>{`. Transactions made on the Optimism deployment will likely be `}<em parentName="p">{`significantly`}</em>{` cheaper!`}</p>
    <p>{`Read on for more details on Starswap v3. For a deeper technical overview check out the `}<a parentName="p" {...{
        "href": "/whitepaper-v3.pdf"
      }}>{`Starswap v3 Core whitepaper`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://github.com/Starswap/Starswap-v3-core/"
      }}>{`Starswap v3 Core smart contracts`}</a>{`.`}</p>
    <h1 {...{
      "id": "concentrated-liquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#concentrated-liquidity",
        "aria-label": "concentrated liquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Concentrated Liquidity`}</h1>
    <p>{`In Starswap v2, liquidity is distributed evenly along an x`}{`*`}{`y=k price curve, with assets reserved for all prices between 0 and infinity. For most pools, a majority of this liquidity is never put to use. As an example, `}<strong parentName="p">{`the v2 DAI/USDC pair reserves just ~0.50% of capital for trading between $0.99 and $1.01`}</strong>{` , the price range in which LPs would expect to see the most volume and consequently earn the most fees.`}</p>
    <p>{`V2 LPs only earn fees on a small portion of their capital, which can fail to appropriately compensate for the price risk (`}{`”`}{`impermanent loss`}{`”`}{`) they take by holding large inventories in both tokens. Additionally, traders are often subject to high degrees of slippage as liquidity is spread thin across all price ranges.`}</p>
    <p>{`In Starswap v3, LP`}{`’`}{`s can `}<strong parentName="p">{`concentrate their capital within custom price ranges, providing greater amounts of liquidity at desired prices.`}</strong>{` In doing so, `}<strong parentName="p">{`LPs construct individualized price curves`}</strong>{` that reflect their own preferences.`}</p>
    <Video option="CYL" description="V3 LPs can concentrate their liquidity within custom price ranges." mdxType="Video" />
    <p>{`LPs can combine any number of distinct concentrated positions within a single pool. For example, an LP in the ETH/DAI pool may choose to allocate $100 to the price ranges $1,000-$2,000 and an additional $50 to the ranges $1,500-$1,750.`}</p>
    <p>{`By doing so, an LP can approximate the shape of any automated market maker or active order book.`}</p>
    <p><strong parentName="p">{`Users trade against the combined liquidity of all individual curves`}</strong>{` with no gas cost increase per liquidity provider. Trading fees collected at a given price range are split pro-rata by LPs proportional to the amount of liquidity they contributed to that range.`}</p>
    <Video option="IS" description="LPs can combine multiple positions, which are then efficiently aggregated into a single order book." mdxType="Video" />
    <h2 {...{
      "id": "capital-efficiency",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#capital-efficiency",
        "aria-label": "capital efficiency permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Capital Efficiency`}</h2>
    <p>{`By concentrating their liquidity, LPs can provide `}<strong parentName="p">{`the same liquidity depth as v2 within specified price ranges while putting far less capital at risk.`}</strong>{` The capital saved can be held externally, invested in different assets, deposited elsewhere in DeFi, or used to increase exposure within the specified price range to earn more trading fees.`}</p>
    <Video option="MFFL" description="Concentrated liquidity allows LPs to provide greater depth in fee generating price ranges." mdxType="Video" />
    <p>{`Let`}{`’`}{`s illustrate with an example:`}</p>
    <p>{`Alice and Bob both want to provide liquidity in an ETH/DAI pool on Starswap v3. They each have `}{`$`}{`1m. The current price of ETH is 1,500 DAI.`}</p>
    <p>{`Alice decides to deploy her capital across the entire price range (as she would have in Starswap v2). She deposits 500,000 DAI and 333.33 ETH (worth a total of `}{`$`}{`1m).`}</p>
    <p>{`Bob instead creates a concentrated position, depositing only within the price range from 1,000 to 2,250. He deposits 91,751 DAI and 61.17 ETH, worth a total of about $183,500. He keeps the other $816,500 himself, investing it however he prefers.`}</p>
    <p>{`While Alice has put down 5.44x as much capital as Bob, `}<em parentName="p">{`they earn the same amount of fees`}</em>{`, as long as the ETH/DAI price stays within the 1,000 to 2,250 range.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ce989cd290d463cbcdf3082af846bbfe/2cb21/example_1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACUElEQVQoz02QW1faUBSED4SEhJALuZ3c7xAuCkhBQah1UbVWV9tVH/r/f8nsruiLD7O+p5nZe5g80Mm0PXL8iEzXJ8PzSfd8DF2fNI+TajvUEWUwQSLWkz7YFT+p91lgykCHZXMUVYPJbAE/yaC7nHQeQvMCDD1Ofc0gJkjoiP139voDiIoKoa9AkOR3dtqyNnRkczw9v+J6f8TlaoNqMoMbJzTZrFAtlmiDZcsh1l7JBOrJKrKqfi9v6QYheBRD1U2wrkDMcny8/vqL88MzirpBUtaweEST5QLL3Qp2mCDMK4ryCu00qmHheHeP6WKJKCuwP33Ft+8PsLkP1ukSG6gmxpMFVutrTC/WrRmGF1A9v6ByNiUvSenl9Q/e3v5h8+WGFM1AXo9RN3OEaY4gSZEUFVTD/HhZNxxcnY44PJ1xdXtAWk8wCiLKmhnieowgy7HZ7elwe4f55ZoMP0CUlyjrBlGWw/YDOH4A1RyB9URimuHg9OMB97+fcbnbopzO4KUFrY5brPdbuHEM0+dkcA7N88jNctz/fMHd4yNmyxVujifcnc8Is+xjw8FwBI/HqMsJJnWDrKjB04Ka9Qrb4wHVfAqexuTEMYwgIDMMMd6s0dzsEI1r8ChBXJTQLAtM6BHrKzoJkkqiolG4KKk5XVG+mCHMS4rrirJxSWGRgmcJ2XEEMwzJSVKyk5SsJCVlZJGoDqnTl6kj9YmJigZJ0dFTNMimhdacVgXFeY4oTxFkMcIiIZ4lcJKYRlEIPfCh+T6GQQDFdcHEPrqyDGmo0X/D/QcV7JdVqQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "example 1",
            "title": "example 1",
            "src": "/static/ce989cd290d463cbcdf3082af846bbfe/c1b63/example_1.png",
            "srcSet": ["/static/ce989cd290d463cbcdf3082af846bbfe/5a46d/example_1.png 300w", "/static/ce989cd290d463cbcdf3082af846bbfe/0a47e/example_1.png 600w", "/static/ce989cd290d463cbcdf3082af846bbfe/c1b63/example_1.png 1200w", "/static/ce989cd290d463cbcdf3082af846bbfe/d61c2/example_1.png 1800w", "/static/ce989cd290d463cbcdf3082af846bbfe/2cb21/example_1.png 2174w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Bob`}{`’`}{`s custom position also acts as a kind of stop-loss for his liquidity. Both Alice and Bob`}{`’`}{`s liquidity will be entirely denominated in ETH if the price of ETH falls to $0. However, Bob will have lost just $159,000, versus Alice`}{`’`}{`s $1m. Bob can use his additional $816,500 to hedge against downside exposure or to invest in any other conceivable strategy.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/76c8a590d8f9302dd2d424e9572c5fbb/2cb21/example_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACQUlEQVQoz0WP2U7jQBREDSGEkNWJt7bb3e09jresggSQRgFBkIbRzP//S92Rg0bzcFS3Xo7qaobr09RiNDZtGho2jUyHRpaDYdtNm3rjKWk3PWid2/951SFN63zn9c03/27GJWSUIsxyqHQBgwtcxJaLkcUu4jt9Tle3fWidLl11e5hbDMxXMBwXg7GOkT7HWJ/jutuDJoIYX7//4PT+ge3DASrJMHM5JZsGUVFjbLsYWg4NTBs3gxF1+0PkZY2yXiPOciyrBmlegKsA3bt7aLO5jbf3M14/zljWK4gowcRilNQF6n3TytGfGXRvmOgbJumuh9PHGfVmB08GaLZ7iDCGJxS6/QG0wUiH60mkWYGsqMFkgIllU5QXFOU5GZxTVtZY7x+wbNY04z6Sqka+3oDHKUxfwHA5ZpaN69u79uUMzdMT9q8/sDoe4McpdOaRzHL4SQpHyhbywhCOUmQKgWy1wvZ4xHKzQVxUSMoKfpygez+EpuIlTr9+4unrjHy/g1rkMIWi5mmH5nELk3PMXIdmLsOUOWRJidPn50WYVBWq0wH1+QXpboUJY9C4SpAuKsRpARmk4CqG6StKmwbb4wFBvoAtONmSwxKcTOHj5e0N++dnRMUSUV0iakqEZYGpx6F5IiTHE2R5PtllQuqxIZ4vYImAWBiSF4XkSB9M+dTiKEE8jsCTGG4UgoXf6JxjxFxog/EczBPgQsELAzihgiUlWb6EJUS76iJzgwuwJad2qeFz0j2XJp5LY7dNTrov6S8CKQ9BoeSDvwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "example 2",
            "title": "example 2",
            "src": "/static/76c8a590d8f9302dd2d424e9572c5fbb/c1b63/example_2.png",
            "srcSet": ["/static/76c8a590d8f9302dd2d424e9572c5fbb/5a46d/example_2.png 300w", "/static/76c8a590d8f9302dd2d424e9572c5fbb/0a47e/example_2.png 600w", "/static/76c8a590d8f9302dd2d424e9572c5fbb/c1b63/example_2.png 1200w", "/static/76c8a590d8f9302dd2d424e9572c5fbb/d61c2/example_2.png 1800w", "/static/76c8a590d8f9302dd2d424e9572c5fbb/2cb21/example_2.png 2174w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Instead of providing equivalent liquidity depth as a v2 LPs with less capital, v3 LPs can choose to provide `}<strong parentName="p">{`greater depth with the same amount of capital`}</strong>{` as their v2 counterparts. This requires `}<strong parentName="p">{`taking on more price risk`}</strong>{` (`}{`”`}{`impermanent loss`}{`”`}{`) while supporting greater amounts of trading and earning higher fees.`}</p>
    <p>{`LPs in more stable pools will likely provide liquidity in particularly narrow ranges. If the ~$25m currently held in the Starswap v2 DAI/USDC pair was instead concentrated between 0.99 — 1.01 in v3, it would provide the same depth as $5bn in Starswap v2 as long as the price stayed within that range. `}<strong parentName="p">{`If the ~$25m was concentrated into the 0.999 - 1.001 range it would provide the same depth as $50b in Starswap v2.`}</strong></p>
    <p>{`The tool below calculates the capital efficiency gains of a concentrated liquidity position (centered around the current price) relative to allocating capital across the entire price curve.`}</p>
    <Simulator mdxType="Simulator" />
    <p>{`At launch, capital efficiency gains will max out at 4000x for LPs providing liquidity within a single 0.10% price range. The v3 pool factory is technically capable of supporting ranges as granular as 0.02%, translating to a maximum 20,000x capital efficiency gains relative to v2. However, more granular pools can increase swap gas costs and might be more useful on Layer 2.`}</p>
    <h2 {...{
      "id": "active-liquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#active-liquidity",
        "aria-label": "active liquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Active Liquidity`}</h2>
    <p>{`If market prices move outside an LP`}{`’`}{`s specified price range, their liquidity is effectively removed from the pool and is no longer earning fees. In this state, an LP`}{`’`}{`s liquidity is composed entirely of the less valuable of the two assets, until the market price moves back into their specified price range or they decide to update their range to account for current prices.`}</p>
    <Video option="AL" description="LPs only earn fees when the market trades between their specified price range." mdxType="Video" />
    <p>{`In v3, it is theoretically possible for no liquidity to exist in a given price range. However, we expect rational LPs to continuously update their price ranges to cover the current market price.`}</p>
    <h2 {...{
      "id": "range-orders",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#range-orders",
        "aria-label": "range orders permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Range Orders`}</h2>
    <p>{`V3`}{`’`}{`s LP customizability opens up a novel order feature to complement market orders, which we are calling `}{`’`}{`range orders`}{`’`}{`.`}</p>
    <p>{`LPs can deposit a single token in a custom price range above or below the current price: if the market price enters into their specified range, they sell one asset for another along a smooth curve while earning swap fees in the process.`}</p>
    <p>{`Depositing to a narrow range feels similar to a traditional limit order. For example, if the current price of DAI is below 1.001 USDC, Alice could add `}{`$`}{`10m worth of DAI to the range of 1.001 — 1.002 DAI/USDC.`}</p>
    <p>{`Once DAI trades above 1.002 DAI/USDC, Alice`}{`’`}{`s liquidity will have fully converted into USDC. Alice must withdraw her liquidity (or use a third-party service to withdraw on her behalf) to avoid automatically converting back into DAI if DAI/USDC starts trading below 1.002.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7915fe0db8ef22545116db2c70fe092f/97582/range.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAACE4AAAhOAFFljFgAAACbElEQVQoz1XSTW/aSACHcb7ANgn2eGb8boN5MTG2ATuEYAiFNNsq6abtptqNokTtvYf9/odnhVuttIdHM6ef/iNNR2qbuJ+0Rf0EP4pxgxDtegRxDz+OCft9lOthSImp1K80pv5/htZ0lO0wLQrSbEqaZcTJgCCK8YKwvR/rDYY4QdBCQtstZijNmSUxjvgvrAW7wuK4Mur1EEpx0jUwhIVhSc5Mge37eGHIm67BiWG2HTHTtgmTBO37nArx38rObyenNNsdzy/f+evpifNsSlcIlOO0+G5/w9PzK/cPn/jy9Ssv374hHRvt+Ty/vvL58ZE/vnzGcmwMJemcGYLxuM+fdyUPvxcs5wnFtEeejxhNxlwfGh4e77g8fKBo3lNu71HRsEWLRcmkLBmeZ4jjk5X6CU4SwW0TsZp71IXPokwoZyn5LCe/bCje3tGvDjiTS9Rki5zsMdwY3R+hB1POtIshrZ9gEEZ8PGTcrCL26xG7dcZmXbJuLlhtN9SbHbOrtxTNLVlzx/jqI5Prv0nrHZayEEoibRvpuO3ZkUqzXkQcrgZsLlNW1Yi6mrCoS2YXF+TLNdnymnS5Z1C/I67eE9Sf8EZzPN/Bj0KUlhjCQGhF582pSZ0H/PM658dLzbttyqwcMasK8qrivFqRVhuG1Z7+4pZg/gGdNqggpr59ZHm4Z7y8wR4WCGnRMYTEdy3ykUk9lYwHLnEvIk4SouGIaJgSjTPiNCdKZwTDAieMscMYb3qFDAfYyRQnOUdISUdYFqaQnBqSk66ka0qE0ljaRToe2vXbv+gGAX4YtrlHMIhQjo3UClMYmKbRLvwXJ01AHyu+ZG0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "range",
            "title": "range",
            "src": "/static/7915fe0db8ef22545116db2c70fe092f/c1b63/range.png",
            "srcSet": ["/static/7915fe0db8ef22545116db2c70fe092f/5a46d/range.png 300w", "/static/7915fe0db8ef22545116db2c70fe092f/0a47e/range.png 600w", "/static/7915fe0db8ef22545116db2c70fe092f/c1b63/range.png 1200w", "/static/7915fe0db8ef22545116db2c70fe092f/d61c2/range.png 1800w", "/static/7915fe0db8ef22545116db2c70fe092f/97582/range.png 2127w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The average execution price of a fully executed range order is the geometric average of the minimum and maximum price: in Alice`}{`’`}{`s case, the execution price equals 1.001499 DAI/USDC for a total of `}{`$`}{`1,001,499. This execution price does not account for additional swap fees earned during the period in which prices trade within the 1.001 — 1.002 DAI/USDC range.`}</p>
    <p>{`Range orders within wider ranges may prove particularly useful for `}<strong parentName="p">{`profit-taking, buying the dip,`}</strong>{` and `}<strong parentName="p">{`primary issuance events`}</strong>{`: in the later use case, issuers are now able to deposit liquidity in a single asset and specify the exact range of prices across which they wish to sell their tokens.`}</p>
    <h2 {...{
      "id": "non-fungible-liquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#non-fungible-liquidity",
        "aria-label": "non fungible liquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Non-Fungible Liquidity`}</h2>
    <p>{`As a byproduct of per-LP custom price curves, liquidity positions are no longer fungible and are not represented as ERC20 tokens in the core protocol.`}</p>
    <p>{`Instead, LP positions will be represented by non-fungible tokens (NFTs). However, common shared positions can be made fungible (ERC20) via peripheral contracts or through other partner protocols. Additionally, trading fees are no longer automatically reinvested back into the pool on LPs`}{`’`}{` behalf.`}</p>
    <p>{`Over time we expect increasingly sophisticated strategies to be tokenized, making it possible for LPs to participate while maintaining a passive user experience. This could include multi-positions, auto-rebalancing to concentrate around the market price, fee reinvestment, lending, and more.`}</p>
    <h1 {...{
      "id": "flexible-fees",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#flexible-fees",
        "aria-label": "flexible fees permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Flexible Fees`}</h1>
    <p>{`Starswap v3 offers LPs three separate fee tiers per pair — 0.05%, 0.30%, and 1.00%. This array of options ensures that LPs tailor their margins according to expected pair volatility: LPs take on more risk in non-correlated pairs like ETH/DAI and, conversely, take on minimal risk in correlated pairs like USDC/DAI.`}</p>
    <p>{`Although distinct fee tiers may lead to some degree of liquidity fragmentation, we believe that most pairs will calibrate to an `}{`’`}{`obvious`}{`’`}{` fee tier, which then serves as the canonical market. We expect like-kind asset pairs to congregate around the 0.05% fee tier and pairs like ETH/DAI to use 0.30%, while exotic assets might find 1.00% swap fees more appropriate. governance can add additional fee tiers as needed.`}</p>
    <p>{`Starswap v2 introduced a protocol fee switch, which allowed a flat 5 basis point (16.66% of LP fees) fee to be turned on by governance. Starswap v3 protocol fees are far more flexible. Fees will be off by default, but can be turned on by governance on a per-pool basis and set between 10% and 25% of LP fees.`}</p>
    <h1 {...{
      "id": "advanced-oracles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#advanced-oracles",
        "aria-label": "advanced oracles permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advanced Oracles`}</h1>
    <p>{`Starswap v2 introduced time weighted average price (TWAP) oracles. These oracles serve as a critical piece of DeFi infrastructure, and have been integrated into dozens of projects, including Compound and Reflexer.`}</p>
    <p>{`V2 oracles work by storing cumulative sums of Starswap pair prices on a per-second basis. These price sums can be checked once at the beginning of a period and once at the end to calculate an accurate TWAP over that period.`}</p>
    <p>{`Starswap v3 offers significant improvements to the TWAP oracle, making it possible to calculate any recent TWAP within the past ~9 days in a single on-chain call. This is achieved by storing an array of cumulative sums instead of just one.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/93cf65bad7a12410c2441dc29fe29834/6e52c/oracles.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAAC50lEQVQ4y22T25LiNhCG5TO2B4zPBmMjH5GNOQ0M7OxOKtlkL/MEeYS8//XfKZuarb3IxVd/SapuqftvMUXTyfVDclyfvCCC4/pwPA+aMQOTFTBF/X/Un9AvSkxRdfKeCTF3XDLNOVmWQ4pmEGMSMUl+IitPPtefe88L6GfC6YAxTMGMwfJNBNkCL3MTlm3BtM2JmTWqBevFhjV/ge0sYNg2JF0n2TDAdJ2YphHTZxYZpg1Z1cjQLTz+PuOff7/ifG5RFBx1U6Fua7RCoDsM6M8nDNcbxOmMsuvgJAkt4hjL1XpUYjlviFcCpj2nVhzx7eMHbtcP3Po7eFlD9EcM5zecrl9weH3D8XbH199+4P3jOy6PP5FWe4pyjt3wSny3f/ZQUXVIsgpVN8gLQri+j6XrwXFdLD0fKefI6wZZVSOrG2zqFkU3IK12CMsthWUON83ITTfEPhstPRWKptHMGttg0tLzaVvWVDY7Ktsn0SajYJNTUpTkrrZ0/X7F469XeonW5G0yYpKikiSPKBiTWvM5/DhCsErgJzGCJEGUrhGu1/BXK0SbDHHOKd4WtBU9dadXdKcLbYUgP8tGY0d3JXy6neUF9ocLeFVNRjRCQPQ9xH6PbjigP52xG47gO4G0KOHGCSVbjni7pSjPia03nNK8xMy0iRct7u+/4/r2DZf+hrJq0O2PGI7XiV1/gNgfcX//A5fbO7rTA1klqGgFxHChSgzEll5Irh9BN0wEQULd/oSuP4KnBcIowSrNEK9SJOsMtejRHc44nO+4ffnA/vIAb3sq2h2abqCi7cYq5alkWVankp2liyCMwSQGVdVIUVRKVinCeDXOK9lLj5wgQtsdwOtmnADKygobXtCaF8TGAEXRSFaexoyvCqOEZFmBomoT4yULZwlVM6DOTFgLl6q2G1+ErKxphDc7yuuWmCwr9CuTQYxBkuTRqKdOX5NBVlUougHFMKHOLJhzB3PXp4UfUrBKJ/4DclRfEqdU/Y4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "oracles",
            "title": "oracles",
            "src": "/static/93cf65bad7a12410c2441dc29fe29834/c1b63/oracles.png",
            "srcSet": ["/static/93cf65bad7a12410c2441dc29fe29834/5a46d/oracles.png 300w", "/static/93cf65bad7a12410c2441dc29fe29834/0a47e/oracles.png 600w", "/static/93cf65bad7a12410c2441dc29fe29834/c1b63/oracles.png 1200w", "/static/93cf65bad7a12410c2441dc29fe29834/d61c2/oracles.png 1800w", "/static/93cf65bad7a12410c2441dc29fe29834/6e52c/oracles.png 1948w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This array of historical price accumulators makes it far easier and cheaper to create more advanced oracles that include simple-moving averages (SMA), exponential moving averages (EMA), outlier filtering, and more.`}</p>
    <p>{`Despite this major improvement, the gas cost to Starswap traders for keeping oracles up to date has been reduced by ~50% relative to v2. The costs for calculating TWAPs in external smart contracts is significantly cheaper as well.`}</p>
    <h1 {...{
      "id": "license",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#license",
        "aria-label": "license permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`License`}</h1>
    <p>{`We strongly believe decentralized financial infrastructure should ultimately be free, open-source software. At the same time, we think the Starswap community should be the first to build an ecosystem around the Starswap v3 Core codebase.`}</p>
    <p>{`With this in mind, Starswap v3 Core will launch under the `}<a parentName="p" {...{
        "href": "https://github.com/Starswap/Starswap-v3-core/blob/main/LICENSE"
      }}>{`Business Source License 1.1`}</a>{`—effectively `}<strong parentName="p">{`a time-delayed GPL-2.0-or-later license`}</strong>{`. The license limits use of the v3 source code in a commercial or production setting for up to two years, at which point it will convert to a GPL license into perpetuity.`}</p>
    <p>{`Starswap governance can accelerate the change to GPL or grant exemptions to the license at any time by updating the files at `}<em parentName="p">{` `}<strong parentName="em">{`v3-core-license-date.Starswap.eth`}</strong>{` `}</em>{` and `}<em parentName="p">{` `}<strong parentName="em">{`v3-core-license-grants.Starswap.eth`}</strong>{` `}</em>{`.`}</p>
    <p>{`Note that the BUSL 1.1 license does not affect integrations. `}<strong parentName="p">{`All code that may be needed for external integrations has been licensed under GPL or MIT`}</strong>{` , including math libraries, peripheral contracts, interfaces, and the developer sdk. Any wallet, interface, mobile app, protocol, or other project will be able to integrate with v3 as expected.`}</p>
    <p>{`For more information on this license, see this `}<a parentName="p" {...{
        "href": "https://mariadb.com/bsl-faq-adopting/"
      }}>{`FAQ`}</a>{`.`}</p>
    <h1 {...{
      "id": "audits-and-bug-bounty",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#audits-and-bug-bounty",
        "aria-label": "audits and bug bounty permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Audits and Bug Bounty`}</h1>
    <p>{`Our team believes security is of the utmost importance — it took hundreds of hours of testing for us to feel comfortable with launching Starswap v3.`}</p>
    <p>{`Our security process for v3 included:`}</p>
    <ul>
      <li parentName="ul">{`A full-length `}<a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v3-core/blob/main/audits/tob/audit.pdf"
        }}>{`audit from Trail of Bits`}</a></li>
      <li parentName="ul">{`A full-length `}<a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v3-core/blob/main/audits/abdk/audit.pdf"
        }}>{`audit from ABDK`}</a></li>
      <li parentName="ul">{`A full audit from `}<a parentName="li" {...{
          "href": "https://samczsun.com/"
        }}>{`samczsun`}</a>{` (no report)`}</li>
      <li parentName="ul">{`An auditing and review process from our engineering team`}</li>
      <li parentName="ul">{`A comprehensive test suite, including the automated tools Echidna and Manticore`}</li>
    </ul>
    <p>{`Major bugs discovered as part of the testing and auditing process were fixed. However, we would like to note that Starswap v3 is an extremely complex protocol and we cannot guarantee all bugs have already been discovered and resolved.`}</p>
    <p>{`To help find any open vulnerabilities, a public bug bounty will run over the next 30 days, with up to `}{`$`}{`500,000 offered for critical bugs. More detail on the public bug bounty can be found `}<a parentName="p" {...{
        "href": "https://github.com/Starswap/Starswap-v3-core/blob/main/bug-bounty.md"
      }}>{`here`}</a>{`.`}</p>
    <h1 {...{
      "id": "launch-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#launch-details",
        "aria-label": "launch details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Launch Details`}</h1>
    <p>{`The Starswap v3 smart contracts will be deployed to the Ropsten, Rinkeby, Kovan, and Görli testnets in the coming days, giving developers time to begin experimenting with the protocol before the official launch.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://github.com/Starswap/Starswap-v3-core"
          }}>{`Starswap v3 Core repository`}</a>{` contains the essential, low-level smart contracts that power the protocol.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://github.com/Starswap/Starswap-v3-periphery"
          }}>{`Starswap v3 Periphery repository`}</a>{` contains a collection of smart contracts designed to ease user interaction with core contracts.`}</p>
      </li>
    </ul>
    <p>{`A liquidity provider migration portal will be available at launch, allowing v2 LPs to seamlessly transfer their liquidity to the new protocol.`}</p>
    <p>{`In the meantime, there are few outstanding items to complete:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Partners and integrations can begin building on Starswap v3 immediately, in preparation for mainnet launch!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The interface, analytics site, API, and developer SDK are being redesigned to work with Starswap v3.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The initial Starswap v3 router and position manager contracts are almost complete. While still subject to change, they are available `}<a parentName="p" {...{
            "href": "https://github.com/Starswap/Starswap-v3-periphery"
          }}>{`here`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Documentation, guides, and further examples are in progress.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Additional infrastructure to support fungibile positions, liquidity mining, more complex strategies, and various other use cases will be built by Starswap Labs together with the Starswap community `}<strong parentName="p">{`after mainnet launch`}</strong></p>
      </li>
    </ul>
    <h2 {...{
      "id": "reach-out",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reach-out",
        "aria-label": "reach out permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reach out`}</h2>
    <p>{`On Friday, March 26 at 2pm ET we will host an AMA on the official Starswap Discord, where we hope to answer any outstanding questions you may have around Starswap v3. Questions can be submitted ahead of time `}<a parentName="p" {...{
        "href": "https://docs.google.com/forms/d/e/1FAIpQLSebgW4Ta1Iq4gaPKvcDExliyGjrfUpvWeJKRAPD1KK7s-cNNw/viewform?usp=sf_link"
      }}>{`here`}</a>{`.`}</p>
    <p>{`We will be releasing further updates, additional information, and initial documentation over the coming days and welcome all feedback and involvement from our community.`}</p>
    <p>{`If you are integrating and wish to get in touch, please fill out this `}<a parentName="p" {...{
        "href": "https://docs.google.com/forms/d/e/1FAIpQLSfH6fFaaCoPlDW570ZYN-9VOS_-Fu9gl8rRFoANtx0XWQtNaQ/viewform?usp=sf_link"
      }}>{`form`}</a>{` and we will try to respond as soon as possible. Alternatively, reach out in the #v3-integrations channel in the community Discord`}</p>
    <p>{`To get involved and stay up to date:`}</p>
    <ul>
      <li parentName="ul">{`Join the Starswap community `}<a parentName="li" {...{
          "href": "https://discord.gg/FCfyBSbCU5"
        }}>{`discord`}</a></li>
      <li parentName="ul">{`Follow Starswap on `}<a parentName="li" {...{
          "href": "/Starswap"
        }}>{`Twitter`}</a></li>
      <li parentName="ul">{`Subscribe to the Starswap `}<a parentName="li" {...{
          "href": "/blog"
        }}>{`blog`}</a></li>
      <li parentName="ul">{`Register as a delegate `}<a parentName="li" {...{
          "href": "http://sybil.org/"
        }}>{`Sybil`}</a></li>
      <li parentName="ul">{`Participate in `}<a parentName="li" {...{
          "href": "http://gov.starswap.xyz/"
        }}>{`Starswap governance`}</a></li>
    </ul>
    <p>{`Starswap Team
🦄`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      